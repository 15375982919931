import { useEffect, useState } from "react";
import io from 'socket.io-client';
import { toast } from "react-toastify";

const Timer = () => {
  const [time, setTime] = useState(60);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  return (
    <div className="App">
      <p>
        A new version of this application is available. The website will reload in {`${Math.floor(time / 60)}`.padStart(2, 0)}:{`${time % 60}`.padStart(2, 0)} seconds. Please save your changes
      </p>
    </div>
  );
}

const useNewReleaseNotification = () => {
  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL); // Replace with your server URL
    socket.on('new-release', (data) => {
      console.log('New release notification received:', data);
      toast.info(<Timer />, { autoClose: false, closeButton: false, draggable: false, closeOnClick: false })
      setTimeout(() => window.location.reload(), 60000)
    });
    return () => {
      socket.disconnect();
    };
  }, []);
};

export default useNewReleaseNotification;
